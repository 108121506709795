var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._m(0),_c('router-link',{attrs:{"to":"/home"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.library'))+" ")])])])]}}])}),(_vm.isAuthenticated)?_c('router-link',{attrs:{"to":"/profile/qrCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.profile'))+" ")])])])]}}],null,false,2454518854)}):_vm._e(),(_vm.isAuthenticated)?_c('router-link',{attrs:{"to":"/profile/book-history"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.bookHistory'))+" ")])])])]}}],null,false,1102866246)}):_vm._e(),(_vm.isAuthenticated)?_c('router-link',{attrs:{"to":"/profile/wish-list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.wishList'))+" ")])])])]}}],null,false,2193258)}):_vm._e(),(_vm.currentUser.type === _vm.Roles.ADMIN || _vm.currentUser.type === _vm.Roles.EDITOR)?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren('/custom') },attrs:{"aria-haspopup":"true","data-menu-toggle":"click"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.administration'))+" ")])]),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/admin/book"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item menu-item-submenu",class:{'menu-item-active': isExactActive},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-open-text-book"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.books'))+" ")])])])]}}],null,false,3885847562)}),_c('router-link',{attrs:{"to":"/admin/loanList"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item menu-item-submenu",class:{'menu-item-active': isExactActive},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-open-text-book"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Ödünç Kitap Listesi")])])])]}}],null,false,1478570954)}),_c('router-link',{attrs:{"to":"/admin/category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item menu-item-submenu",class:{'menu-item-active': isExactActive},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-indent-dots"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.categories'))+" ")])])])]}}],null,false,148231803)}),_c('router-link',{attrs:{"to":"/admin/author"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item menu-item-submenu",class:{'menu-item-active': isExactActive},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-sheet"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.authors'))+" ")])])])]}}],null,false,1844895097)}),_c('router-link',{attrs:{"to":"/admin/user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item menu-item-submenu",class:{'menu-item-active': isExactActive},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-group"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.users'))+" ")])])])]}}],null,false,2599397613)}),_c('router-link',{attrs:{"to":"/admin/comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item menu-item-submenu",class:{'menu-item-active': isExactActive},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-talk"}),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t('project.comments'))+" ")])])])]}}],null,false,445914882)})],1)])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"https://camiakademi.com/"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Câmiakademi ")])])])}]

export { render, staticRenderFns }